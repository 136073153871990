<template>
    <div class="home">
        <CO2M />
    </div>
</template>

<script>
// @ is an alias to /src
import CO2M from '@/components/Scheme.vue'

export default {
    name: 'Home',
    components: {
        CO2M
    }
}
</script>
