<template>

    <div class="co2m">
        <div class="steps" v-if="step>0">

            <div class="legend" :style="{top:((step-1)*24)+12+'px'}">{{legends[step-1]}}</div>
            

            <div class="step" :style="{top:index*24+'px'}" v-for="index in 12"><span v-if="step==index">&#9664;</span></div>
        </div>
        <div class="container" :class="{active:step > 1}">
            <div class="start" @click="stepUp" :class="{active: step > 0}">START<span>&#9654;</span></div>
            <div class="logo" :class="{active : step > 0}">
                Carbon<span><sub>2</sub>Minerals</span>
            </div>
            <div class="co2" :class="{active:step > 1}">
                <div class="c"></div>
                <div class="o o-1"></div>
                <div class="o o-2"></div>
                <div class="fabric">
                    <div class="bar bar-v" :class="{active: step > 2}"></div>
                    <div class="bar bar-h" :class="{active: step > 2}"></div>
                    <div class="bar bar-13v" :class="{active:step > 10}"></div>
                    <div class="bar bar-13h" :class="{active:step > 10}"></div>
                </div>
            </div>
            <div class="h2o" :class="{active:step > 2}">
                <div class="water" :class="{carbonate:step > 3}">
                    <div class="inner"></div>
                </div>
            </div>
            <div class="minerals" :class="{active:step > 3}">
                <div class="mineral m-a" :class="{grinding:step>4}"></div>
                <div class="mineral m-b" :class="{grinding:step>4}"></div>
                <div class="grind" :class="{active:step>4}">Grinding</div>
            </div>
            <div class="powder" :class="{active:step > 4}"></div>
            <div class="blending-bars">
                <div class="bar b-1" :class="{active:step > 5}"></div>
                <div class="bar b-2" :class="{active:step > 5}"></div>
                <div class="bar b-3" :class="{active:step > 5}"></div>
            </div>
            <div class="trechter" :class="{active:step > 5}">
                <div class="inner">
                    <div class="blending">Blending</div>
                </div>
            </div>
            <div class="slurry" :class="{active:step > 6}">Slurry</div>
            <div class="dewatering" :class="{active:step > 7}">Dewatering</div>
            <div class="cleaning" :class="{active:step > 8}">
                <div class="inner"></div>

                <div class="bar bar-v1"></div>
                <div class="bar bar-h"></div>
                <div class="bar bar-v2"></div>
                <span>Water<br>cleaning</span>
            </div>
            <div class="cake" :class="{active:step > 9}">CAKE</div>
             <div class="trommel" :class="{active:step > 10}">
                <div class="inner">Rotary kiln</div>
            </div>
            <div class="aggregaat" :class="{active:step > 11}">Aggregate</div>
        </div>
    </div>
    
</template>

<style scoped lang="scss">
$green : #548334;
$bluegrey : #384759;
$red:#8C0000;
$red-light:#EB6262;
$black:#000;

$blue1:#2CABD0;
$blue2:#2C75D0;
$blue3:#33547C;
$purple1:#8C4CBB;
$purple2:#3A1753;

@font-face {
    font-family: "Segoe UI";
    src: url("/segoe-ui/Segoe UI.ttf");
}
@font-face {
    font-family: "Segoe UI";
    src: url("/segoe-ui/Segoe UI Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: "Segoe UI";
    src: url("/segoe-ui/Segoe UI Italic.ttf");
    font-style: italic;
}
@font-face {
    font-family: "Segoe UI";
    src: url("/segoe-ui/Segoe UI Bold Italic.ttf");
    font-weight: bold;
    font-style: italic;
}
.steps{
    width: 24px;
    position: absolute;
    left: 0;
    top: 60px;
    .legend{
        background: linear-gradient(#fbfbfb, #eee);
        padding: 16px;
        color: $bluegrey;
        font-size: 18px;
        border-radius: 8px;
        position: absolute;
        min-width: 240px;
        top: 12px;
        left: 60px;
        text-align: left;
    }
    .step{
        position: absolute;
        width: 240px;
        line-height: 32px;
        text-align: left;
        padding: 0 36px;
        &:before{
            position: absolute;
            content: '';
            width: 24px;
            height: 2px;
            background: $green;
            margin: 15px 0 0 -36px;
        }
        span{
            position: absolute;
            font-size: 14px;
            margin:1px 0 0  -8px;
            color: $green;
        }
    }
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Segoe UI';
    transition: height .225s;

    &.active{
        height: 100px;
    }

    .start{
        padding: 0 24px;
        line-height: 36px;
        font-size: 24px;
        color: $green;
        span{
            position: absolute;
            font-size: 14px;
            padding: 3px;
        }
        &.active{
            display: none;
        }
    }
    .logo{
        transform: scale3d(0,0,0);
        color: $green;
        font-family: 'Segoe UI';
        font-size: 44px;
        text-transform:uppercase;
        position: absolute;
        &.active{
        transition: transform .225s;
            transform: scale3d(1,1,1);
        }
        span{
            color: $bluegrey;
        }
    }
    .co2{
        position: absolute;
        width: 80px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:  120px 0 0 -460px;
        transform: scale3d(1,0,1);
        transition: transform .225s;
        transition-delay: .225s;
        transform-origin: 50% 0;
        &.active{
            transform: scale3d(1,1,1);
        }

        .c{
            position: absolute;
            z-index: 2;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: $black;
            animation: grow 1.5s infinite;
            animation-delay: .5s;
        }
        .o{
            z-index: 1;
            position: absolute;
            content: '';
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: $red;
            margin-top: 24px;
            margin-left: -36px;
            animation: grow 1.5s infinite;

            &.o-2{
                z-index: 3;
                width: 42px;
                height: 42px;
                z-index: 3;
                margin-top: -24px;
                margin-left: 36px;
                animation-delay: 1s;
            }
        }

        .fabric{
            position: absolute;
            background: $black;
            width: 32px;
            height: 120px;
            border-radius: 5px;
            margin: 200px 0 0 -38px;
            &:before{
                position: absolute;
                content: '';
                background: white;
                width: 50%;
                height: 100%;
                left: -42%;
                transform: skew(-1.5deg);
            }
            &:after{
                transform: skew(1.5deg);
                position: absolute;
                content: '';
                background: white;
                z-index: 4;
                right: -42%;
                width: 50%;
                height: 100%;
            }
            .bar{
                position: absolute;
                width: 8px;
                height: 36px;
                background: linear-gradient($red-light, $red-light 50%, $black 50%, $black 100%);
                background-size: 8px 16px;
                top: 120px;
                margin-left: 12px;
                animation: move-ver .225s linear infinite;
                transform: scale3d(1,0,1);
                -webkit-transform-origin: 50% 0;
                transition: transform .225s;
                transition-delay: 1s;
                &.active{
                    transform: scale3d(1,1,1);
                }
                &.bar-h{
                    width: 148px;
                    height: 8px;
                    top: 156px;
                    background: linear-gradient(to right, $red-light, $red-light 50%, $black 50%, $black 100%);
                    background-size: 16px 8px;
                    animation: move-hor .225s linear infinite;

                    transform: scale3d(0,1,1);
                    -webkit-transform-origin: 0 50%;
                    transition: transform .225s;
                    transition-delay: 1.225s;
                    &.active{
                        transform: scale3d(1,1,1);
                    }
                }
                &.bar-13h{
                    width: 552px;
                    height: 4px;
                    top: 4px;
                    margin-left: 28px;
                    z-index: 5;
                    background: linear-gradient(to right, $red-light, $red-light 50%, $black 50%, $black 100%);
                    background-size: 8px 4px;
                    animation: move-cement-hor 2s linear infinite;
                    transform: scale3d(0,1,1);
                    -webkit-transform-origin: 0 50%;
                    transition: transform .225s;
                    transition-delay: .45s;
                    &.active{
                        transform: scale3d(1,1,1);
                    }
                }
                &.bar-13v{
                    width: 4px;
                    height: 300px;
                    top: 4px;
                    margin-left: 580px;
                    z-index: 5;
                    background: linear-gradient($red-light, $red-light 50%, $black 50%, $black 100%);
                    background-size: 4px 8px;
                    animation: move-ver .4s linear infinite;
                    transform: scale3d(1,0,1);
                    -webkit-transform-origin: 50% 0;
                    transition: transform .225s;
                    transition-delay: .675s;
                    &.active{
                        transform: scale3d(1,1,1);
                    }

                }
            }
        }
    }
    .h2o{
        position: absolute;
        top: 180px;
        width: 80px;
        height: 160px;
        border: 2px black solid;
        border-top-left-radius: 50% 10%;
        border-top-right-radius: 50% 10%;
        border-bottom-left-radius:50% 10%;
        border-bottom-right-radius:50% 10%;
        overflow: hidden;
        margin-left: -200px;
        transform: scale3d(1,0,1);
        -webkit-transform-origin: 50% 100%;
        transition: transform .255s;

        &.active{
            transform: scale3d(1,1,1);
            .water{
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        &:before{
            content: '';
            width: 100%;
            height: calc(10% + 2px);
            margin-left: calc(-50% - 2px);
            margin-top: -2px;
            position: absolute;
            border: 2px black solid;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius:50%;
            border-bottom-right-radius:50%;
        }
        .water{
            margin-top: 40%;
            width: 100%;
            height: 80%;
            background-image: linear-gradient($blue1,$blue2);
            border-top-left-radius: 50% 10%;
            border-top-right-radius: 50% 10%;
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform: translate3d(0, 100%, 0);
            transition: all 1s;
            transition-delay: .225s;
            
            &.carbonate{
                transition-delay: 2s;
                transition: filter 2s;
                filter: brightness(60%);
            }
            .inner{
                background: $blue2;
                width: 100%;
                height: 15%;
                border-radius: 50%;
            }
        }
    }

    .minerals{
        position: absolute;
        top: 100px;
        margin-left: 220px;
        display: flex;
        transform: scale3d(0,0,0);
        transition: transform .225s;
        &.active{
            transform: scale3d(1,1,1);
        }
        .mineral{
            width: 48px;
            height: 48px;
            background: url('/mineral.png');
            background-size: cover;
            margin: 0 12px;
            &:after{
                position: absolute;
                content: 'Olivine';
                margin-left: -24px;
                margin-top: 52px;
            }
            &.m-b:after{
                content: 'Steelslag';
                margin-left: -30px;
            }
            &.grinding{
                animation: spin 1s linear infinite;
                &:after{
                    display: none;
                }
            }
        }
        .grind{
            display: none;
            position: absolute;
            margin: 90px 0 0 40px;
            &.active{
                display: block;
                animation: grow 1s infinite;
            }
        }
    }
    .powder{
        width: 160px;
        height: 100px;
        background: url('/powder.png');
        background-size: cover;
        position: absolute;
        top: 240px;
        margin-left: 212px;
        -webkit-transform-origin: 50% 0;
        transform: scale3d(0, 0, 0);
        transition: transform .8s;
        transition-delay: 1s;
        &.active{
            transform: scale3d(1, 1, 1);
        }
    }
    .blending-bars{
        position: absolute;
        .bar{
            position: absolute;
            width: 8px;
            height: 84px;
            background: linear-gradient($blue3, $blue3 50%, $black 50%, $black 100%);
            background-size: 8px 16px;
            top: 262px;
            margin-left: -8px;
            animation: move-ver .225s linear infinite;
            transform: scale3d(1,0,1);
            -webkit-transform-origin: 50% 0;
            transition: transform .225s;
            transition-delay: .225s;
            &.active{
                transform: scale3d(1,1,1);
            }

            &.b-1,&.b-2{
                transition-delay: 0;
                transform: scale3d(0,1,1);
                width: 52px;
                height: 8px;
                top: 256px;
                background: linear-gradient(to right, $blue3, $blue3 50%, $blue1 50%, $blue1 100%);
                background-size: 16px 8px;
                animation: move-hor .225s linear infinite;
                &.b-1{
                    -webkit-transform-origin: 0 50%;
                    margin-left: -58px;
                }
                &.b-2{
                    -webkit-transform-origin: 100% 50%;
                    background: linear-gradient(to right, #999, #999 50%, black 50%, black 100%);
                    background-size: 16px 8px;
                    animation: move-hor .225s reverse linear infinite;
                    margin-left: -6px;
                }

                &.active{
                    transform: scale3d(1,1,1);
                }
            }
        }

    }
    .trechter{
        position: absolute;
        width: 160px;
        height: 160px;
        border-radius: 5px;
        top: 394px;
        overflow: hidden;
        z-index: 10;

        .inner{
            border: 2px $black solid;
            width: 100%;
            height: 100%;
            animation: shake 1s linear infinite;
            background: linear-gradient($purple1, $purple2);

            .blending{
                position: absolute;
                width: 100%;
                height: 40px;
                margin: 60px 0;
                color: white;
                text-align: center ;
                font-size: 20px;
            }

            &:before{
                position: absolute;
                content: '';
                background: white;
                width: 50%;
                height: 100%;
                left: -50%;
                transform: skew(40deg);
                border-bottom: 2px white solid;
                border-right: 2px $black solid;
            }
            &:after{
                transform: skew(-40deg);
                position: absolute;
                content: '';
                background: white;
                z-index: 4;
                right: -50%;
                width: 50%;
                height: 100%;
                border-bottom: 2px white solid;
                border-left: 2px $black solid;
            }
        }

        transform: scale3d(0, 0, 0);
        transition: transform .225s;
        transition-delay:.45s;

        &.active{
            transform: scale3d(1, 1, 1);
        }
    }
    .slurry{
        position: absolute;
        width: 120px;
        height: 120px;
        background: url('/slurry.jpg');
        background-size: cover;
        border-radius: 8px;
        border: 1px $black solid;
        color: white;
        font-size: 36px;
        line-height: 120px;
        top: 600px;
        -webkit-transform-origin: 50% 0;
        transform: translate3d(0, -60px, 0px) scale3d(0, 0, 0);
        transition: transform 1s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
        }
    }

    .cake{
        position: absolute;
        width: 120px;
        height: 120px;
        background: url('/cake.jpg');
        background-size: cover;
        border-radius: 8px;
        border: 1px $black solid;
        color: white;
        font-size: 36px;
        line-height: 120px;
        top: 600px;
        margin-left: 332px;
        -webkit-transform-origin: 0 50%;
        transform: translate3d(-60px, 0, 0px) scale3d(0, 0, 0);
        transition: transform .45s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
        }
    }

    .trommel{
        position: absolute;
        width: 240px;
        height: 120px;
        background: url('/trommel.png') no-repeat;
        background-size: contain;
        border-radius: 8px;
        color: $bluegrey;
        font-size: 16px;
        line-height: 248px;
        top: 420px;
        margin-left: 542px;
        -webkit-transform-origin: 0 100%;
        transform: translate3d(-20px, 120px, 0px) scale3d(0, 0, 0);
        transition: transform .45s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
        }
    }

    .aggregaat{
        position: absolute;
        width: 180px;
        height: 120px;
        background: url('/aggregaat.jpg') no-repeat;
        background-size: cover;
        border-radius: 50%;
        color: white;
        font-size: 32px;
        line-height: 120px;
        top: 560px;
        margin-left: 852px;
        -webkit-transform-origin: 0 100%;
        transform: translate3d(40px, -180px, 0px) scale3d(0, 0, 0);
        transition: transform .45s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
        }
    }
    .dewatering{
        position: absolute;
        width: 240px;
        height: 120px;
        background: url('/dewatering.jpg');
        background-position: center 65%;
        background-size: cover;
        border-radius: 8px;
        color: $blue1;
        font-size: 20px;
        text-align: left;
        line-height: 200px;
        box-sizing: border-box;
        padding-left: 12px;
        top: 600px;
        margin-left: -420px;
        -webkit-transform-origin: 100% 50%;
        transform: translate3d(60px, 0, 0px) scale3d(0, 0, 0);
        transition: transform .45s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
        }
    }

    .cleaning{
        position: absolute;
        margin-left: -428px;
        top: 472px;
        width: 100px;
        height: 100px;
        border-radius: 50%;

        -webkit-transform-origin: 50% 100%;
        transform: translate3d(0, 90px, 0px) scale3d(0, 0, 0);
        transition: transform .45s;
        &.active{
            transform: translate3d(0, 0, 0px) scale3d(1, 1, 1);
            .bar{
                &.bar-v1,&.bar-v2,&.bar-h{
                    transform: scale3d(1,1,1);
                }
            }
            
        }
        

        .inner{
            color: white;
            text-align: center;
            font-size: 18px;
            border: 1px $black solid;
            border-radius: inherit;
            padding-top: 30px;
            box-sizing: border-box;
            line-height: 20px;
            width: 100%;
            height: 100%;
            position: absolute;
            background-image: radial-gradient( circle farthest-side at 10% 50%, $blue1, $blue2 );
            animation: spin 1s linear infinite;
        }

        span{
            position: absolute;
            z-index: 11;
            width: 100%;
            margin-left: -50%;
            line-height: 20px;
            top: 30px;
            color: white;
            text-align: center;
            font-size: 18px;
        }

        .bar{
            position: absolute;
            width: 8px;
            height: 182px;
            background: linear-gradient($blue1, $blue1 50%, $blue2 50%, $blue2 100%);
            background-size: 8px 16px;
            top: -80px;
            margin-left: 48px;
            animation: move-ver .225s reverse linear infinite;
            transform: scale3d(1,0,1);
            -webkit-transform-origin: 50% 100%;
            transition: transform .255s;
            transition-delay: .45s;

            &.bar-v1{
                height: 80px;
            }
            &.bar-v2{
                margin-left: 157px;
                height: 56px;
                top: -128px;
                transition-delay: .9s;
            }

            &.bar-h{
                width: 108px;
                height: 8px;
                top: -80px;
                margin-left: 50px;
                background: linear-gradient(to right, $blue1, $blue1 50%, $blue2 50%, $blue2 100%);
                background-size: 16px 8px;
                animation: move-hor .225s linear infinite;
                transition-delay: .675s;
                transform: scale3d(0,1,1);
                -webkit-transform-origin: 0% 50%;
            }
        }
    }
}
@keyframes spin{
    from{
        transform: rotateZ(0);
    }
    to{
        transform: rotateZ(360deg);
    }
}
@keyframes grow{
    0%,100%{
        transform: scale3d(1.0, 1.0, 1.0);
    }
    50%{
        transform: scale3d(1.1, 1.1, 1.1);
    }
}

@keyframes move-ver{
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 16px;
    }
}
@keyframes move-hor{
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 16px 0;
    }
}
@keyframes move-cement-hor{
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 120px 0;
    }
}

@keyframes shake {
  10%, 100% {
    transform: translate3d(-2px, 2px, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -1px, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

<script>
export default {
    name: 'CO2M',
    data : () => {
        return {
            step : 0,
            legends : [
                'Introduction',
                'CO2 Flue gas',
                'Carbonating water (70% gas)',
                'Natural or industrial minerals',
                'Grinding minerals to powder',
                'Blending carbo H2O and powder',
                'Retrieving slurry',
                'Dewatering slurry',
                'Cleaning water',
                'Dewatered cake',
                'Rotary kiln (30% gas)',
                'Semi dry aggregate'
            ]
        }
    },
    props: {
        msg: String
    },
    methods : {
        stepUp(){
            this.step++;
        }
    },
    mounted(){
        document.addEventListener('keydown', (e) => {
            let x = e.which || e.keyCode;
            if(x == 39){
                this.step++;
            }
            if(x == 37 && this.step > 0){
                this.step--;
            }
        });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

